
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { Guide } from "@/store/guide/types";

import { useFilter, usePagination } from "@/libs/utils";
import { formatDate, formatHours, formatCPFToLGPD } from "@/libs/utils";

const VGuideList = defineComponent({
  name: "VGuideList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de guias eletrônicas | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const guides = computed(() => store.state.guide.list);
    const loading = reactive({ list: false });

    const breadcrumb = [
      {
        label: "Guias eletrônicas",
        icon: "fas fa-clipboard-list mr-2",
        to: { name: "guide-list", query: route.query },
      },
    ];

    async function handlePrint(guide: Guide) {
      const _id = String(guide.cd_horario_agendamento);
      const response = await store.dispatch("getPDFGuide", { _id });
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));
    }

    function getDropdownOptions(guide: Guide) {
      const items: DropdownItem[] = [];

      if (guide.nm_situacao === "EXECUTADA")
        items.push({ label: "Imprimir", icon: "fas fa-print", command: () => handlePrint(guide) });
      else {
        items.push({
          label: "Enviar mensagem",
          icon: "fab fa-whatsapp",
          to: { name: "guide-contact-whatsapp", params: { _id: guide.id_guia_eletronica.toString() } },
        });
        items.push({
          label: "Enviar e-mail",
          icon: "far fa-envelope",
          to: { name: "guide-contact-email", params: { _id: guide.id_guia_eletronica.toString() } },
        });
        items.push({
          label: "Reenviar guia",
          icon: "far fa-paper-plane",
          command: () => sendGuideEmail(guide),
        });
      }

      return items;
    }

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "guide",
      setPage: "setGuidePaginationPage",
      updateList: getGuides,
    });

    async function getGuides() {
      loading.list = true;
      await store.dispatch("getGuides");
      loading.list = false;
    }

    async function sendGuideEmail(guide: Guide) {
      if (guide.cd_horario_agendamento)
        await store.dispatch("sendGuideEmail", { cd_horario_agendamento: guide.cd_horario_agendamento });
    }

    if (hasFilters.value) router.push({ name: "guide-filter", query: route.query });
    else getGuides();

    return {
      ...{ guides, pagination, loading, breadcrumb, hasFilters },
      ...{ formatDate, formatHours, formatCPFToLGPD },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getGuides },
    };
  },
});

export default VGuideList;
